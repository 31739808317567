<template>
    <svg :class="rotation" class="*:transition-color" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle :class="backgroundClass" cx="20.7695" cy="20" r="20" />
        <path
            :class="foregroundClass"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.7695 28.75C20.4244 28.75 20.1445 28.4702 20.1445 28.125L20.1445 13.3839L16.2115 17.3169C15.9674 17.561 15.5717 17.561 15.3276 17.3169C15.0835 17.0729 15.0835 16.6771 15.3276 16.4331L20.3276 11.4331C20.5717 11.189 20.9674 11.189 21.2115 11.4331L26.2115 16.4331C26.4556 16.6771 26.4556 17.0729 26.2115 17.3169C25.9674 17.561 25.5717 17.561 25.3276 17.3169L21.3945 13.3839L21.3945 28.125C21.3945 28.4702 21.1147 28.75 20.7695 28.75Z"
            stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    backgroundClass: {
        type: String,
        default: "fill-white",
    },
    foregroundClass: {
        type: String,
        default: "fill-black stroke-black",
    },
    direction: {
        type: String,
        default: "top",
    },
});

const directionClass = {
    up: "",
    right: "rotate-90",
    down: "rotate-180",
    left: "-rotate-90",
};

const rotation = computed(() => directionClass[props.direction]);
</script>
