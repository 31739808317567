<template>
    <dialog
        id="recording-test-dialog"
        ref="dialogElement"
        data-testid="recording-test-dialog"
        class="focus-visible:outline-0 bg-white md:p-16 p-4 rounded-xl text-valence-grey-800">
        <div class="flex flex-col gap-4 text-center max-w-prose">
            <div class="text-xl font-bold">I&apos;m having trouble hearing you.</div>
            <template v-if="error">
                <p class="text-xs max-w-xs text-center text-red-400" v-text="errorMessage"></p>
            </template>
            <div v-if="loading" class="text-gray-400 flex justify-center p-4">
                <div class="h-6 w-6"><LoadingSpinner /></div>
            </div>
            <div v-show="!loading && shouldShowMicTest" class="flex flex-col gap-4">
                <p class="max-w-sm mx-auto text-base">Can you confirm that you&apos;re using the correct mic. Speak and pause. Do you hear a replay?</p>
                <div class="flex flex-col gap-2 mt-4">
                    <div class="flex items-center justify-between w-full text-xs text-gray-500">
                        <label for="microphone-select"> Select microphone </label>
                        <span v-text="testingLabel"></span>
                    </div>
                    <select id="microphone-select" ref="selectElement" v-model="micSelection" autofocus class="w-full p-2 bg-[#F1F1F1] rounded-lg" name="microphoneSelect">
                        <template v-for="mic in mics" :key="mic.deviceId">
                            <option :value="mic.deviceId">{{ mic.label }}</option>
                        </template>
                    </select>
                </div>
                <div class="flex flex-col justify-start text-left gap-2">
                    <div class="text-xs text-gray-500">Input level</div>
                    <div role="presentation " class="flex flex-row space-between gap-2">
                        <template v-for="(item, idx) in levelSignifiers" :key="idx">
                            <div class="h-5 w-2 rounded-lg" :class="idx < audioLevel ? 'bg-black' : 'bg-gray-300'"></div>
                        </template>
                    </div>
                </div>
                <div class="mt-4">
                    <BaseButton theme="primary" @click="confirmMicSelection">Use this Mic</BaseButton>
                </div>
            </div>
            <button v-if="!loading" type="button" class="text-xs underline text-gray-600 hover:text-gray-600/80" @click="cancel">{{ dismissText }}</button>
        </div>
    </dialog>
</template>

<script>
import { isInIFrame } from "~vue/utils.js";
import { logUserInteraction } from "~vue/utils/logUtils.js";

import BaseButton from "./components/BaseButton.vue";
import { CHAT_EVENT } from "./events.js";
import LoadingSpinner from "./icons/LoadingSpinner.vue";

const ERRORS = {
    NO_INPUT_DEVICES: "no_devices",
    NOT_FOUND: "not_found",
    NOT_ALLOWED: "not_allowed",
    CATCH_ALL: "catch_all",
};

// How many audio level signifiers to show in the UI
const LEVELS_SIGNIFIERS = 25;

export default {
    name: "RecordingTestDialog",
    components: {
        LoadingSpinner,
        BaseButton,
    },
    props: {
        eventSlug: {
            type: String,
            default: undefined,
        },
        dismissText: {
            type: String,
            default: "Use text instead",
        },
    },
    emits: ["micSelected", "dismiss"],
    data() {
        return {
            loading: true,
            mics: [],
            micSelection: undefined,
            audioOutputSupported: false,
            error: null,
            mediaStream: null,
            audioLevel: 0,
            audioContext: null,
            audioNodes: [],
            pollingIntervalId: null,
        };
    },
    computed: {
        levelSignifiers() {
            // Creates an Array with N elements, where N = LEVELS_SIGNIFIERS.
            // Just used to loop it in the template to draw the signifiers
            return Array.apply(null, Array(LEVELS_SIGNIFIERS));
        },
        errors() {
            return ERRORS;
        },
        errorMessage() {
            switch (this.error) {
                case ERRORS.NOT_ALLOWED:
                    return `Oops! I was having trouble hearing you. It looks like your microphone permissions are not turned on ${isInIFrame() ? "in Microsoft Teams" : "in your browser"}. Please turn them on and try again.`;
                case ERRORS.NO_INPUT_DEVICES:
                    return "Oops! I was having trouble hearing you. It looks like there is no microphone connected. Please connect a microphone and try again";
                case ERRORS.CATCH_ALL:
                default:
                    return "Oops! I was having trouble hearing you. It looks like your microphone permissions are not turned on in your browser. Please turn them on and try again.";
            }
        },
        shouldShowMicTest() {
            return this.error === null || ![ERRORS.NO_INPUT_DEVICES, ERRORS.NOT_ALLOWED, ERRORS.NOT_FOUND].includes(this.error);
        },
        testingLabel() {
            const index = this.mics.findIndex((mic) => mic.deviceId === this.micSelection);
            return `${index + 1}/${this.mics.length}`;
        },
    },
    watch: {
        async micSelection() {
            // Recreate the stream and audio playback if microphone selection changes
            await this.teardown();
            this.createAudioPlayback();
        },
    },
    mounted() {
        if (this.$refs.dialogElement) {
            this.$refs.dialogElement.addEventListener("cancel", this.cancel);
        }

        this.emitter.on(CHAT_EVENT.OPEN_MIC_TEST_DIALOG, this.open);
    },
    unmounted() {
        this.teardown();
        clearInterval(this.pollingIntervalId);
        if (this.$refs.dialogElement) {
            this.$refs.dialogElement.removeEventListener("cancel", this.cancel);
        }

        this.emitter.off(CHAT_EVENT.OPEN_MIC_TEST_DIALOG, this.open);
    },
    methods: {
        async start() {
            // Enumerating the mics requires having permissions to access the media devices so we prompt once first. This is a noop if the user has already granted permission elsewhere before.
            try {
                this.micSelection = undefined;
                this.error = null;
                this.mediaStream = await this.requestPermission({ audio: true });
                // Teardown the temporary stream
                await this.teardown();

                // Query the microphones, set the list and default mic
                await this.setDeviceInformation();

                // Query for mics at an interval to pick up any microphone that gets
                // connected or disconnected.
                this.pollingIntervalId = setInterval(this.setDeviceInformation, 2000);

                // Show dialog content now that we have the populated the device list
            } catch (e) {
                this.handleMediaDeviceError(e);
                clearInterval(this.pollingIntervalId);
            }

            this.$nextTick(() => {
                this.loading = false;
                if (this.$refs.selectElement) {
                    this.$refs.selectElement.focus();
                }
            });
        },
        handleMediaDeviceError(error) {
            switch (error.name) {
                case "NotAllowedError":
                    this.error = ERRORS.NOT_ALLOWED;
                    return;
                case "NotFoundError":
                    this.error = ERRORS.NOT_FOUND;
                    return;
            }
            this.error = ERRORS.CATCH_ALL;
        },
        async setDeviceInformation() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                this.mics = devices.filter((device) => device.kind === "audioinput" && device.deviceId !== "");
                this.audioOutputSupported = devices.some((device) => device.kind === "audiooutput");

                if (this.mics.length === 0) {
                    // No audio input devices were found.
                    this.error = ERRORS.NO_INPUT_DEVICES;
                    return;
                } else {
                    this.error = null;
                }

                // Default to first audio input if no selection has been made,
                // or if a selection exists for a mic that no longer exists.
                // The browser's default is the first one.
                if (!this.micSelection || !this.mics.find((mic) => mic.deviceId === this.micSelection)) {
                    this.micSelection = this.mics[0].deviceId;
                }
            } catch (e) {
                this.handleMediaDeviceError(e);
            }
        },
        requestPermission(constraints = {}) {
            return navigator.mediaDevices.getUserMedia(constraints);
        },
        async dismiss() {
            await this.teardown();
            this.$refs.dialogElement.close();
        },
        async cancel() {
            logUserInteraction("choose_mic_modal_text_selected", {}, this.eventSlug);
            this.$emit("dismiss");
            await this.dismiss();
        },
        async confirmMicSelection() {
            const mic = this.mics.find((mic) => mic.deviceId === this.micSelection);
            logUserInteraction("choose_mic_modal_mic_selected", {}, this.eventSlug);
            this.$emit("micSelected", mic);
            await this.dismiss();
        },
        async teardown() {
            if (this.mediaStream) {
                this.mediaStream.getAudioTracks().forEach((track) => track.stop());
            }

            this.audioNodes.forEach((node) => node.disconnect());

            if (this.audioContext) {
                await this.audioContext.close();
            }

            this.audioNodes = [];
            this.audioContext = null;
            this.mediaStream = null;
        },
        open() {
            this.$refs.dialogElement.showModal();
            this.start();
            logUserInteraction("choose_mic_modal_viewed", {}, this.eventSlug);
        },
        async createAudioPlayback() {
            this.mediaStream = await this.requestPermission({
                audio: {
                    deviceId: this.micSelection,
                },
            });

            this.audioContext = new AudioContext();

            // Source contains the audio input stream
            const source = this.audioContext.createMediaStreamSource(this.mediaStream);

            // Delay is used to delay the playback to the user by 300ms
            const delay = this.audioContext.createDelay();
            delay.delayTime.value = 300;

            // Analyser is used for the audio levels visualization.
            const analyser = this.audioContext.createAnalyser();
            analyser.fftSize = 256;

            // Store these nodes for proper teardown elsewhere
            this.audioNodes.push(source, delay, analyser);

            const bufferLength = analyser.frequencyBinCount;
            const levelsArray = new Uint8Array(bufferLength);

            // Pass audio stream to analyser node
            source.connect(analyser);

            if (this.audioOutputSupported) {
                // Enable playback only if there's an audio output device
                source.connect(delay);
                delay.connect(this.audioContext.destination);
                logUserInteraction("choose_mic_audio_playback", {}, this.eventSlug);
            }

            const getAudioLevels = () => {
                analyser.getByteFrequencyData(levelsArray);

                // Calculate the average of the frequency data
                let sum = 0;
                for (let i = 0; i < bufferLength; i++) {
                    sum += levelsArray[i];
                }
                const average = sum / bufferLength;

                this.audioLevel = Math.round((average / 255) * LEVELS_SIGNIFIERS);
                // Run at levels analysis on a browser-controlled loop
                requestAnimationFrame(getAudioLevels);
            };

            getAudioLevels();
        },
    },
};
</script>

<style type="postcss">
dialog#recording-test-dialog::backdrop {
    background-color: #000;
    opacity: 0.4;
}
</style>
